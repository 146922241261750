import React from 'react';
import { Box, Typography } from '@mui/material';
import { graphql, useStaticQuery } from 'gatsby';

// const InfectionsTooltip = styled(({ className, ...props }) => (
//     <Tooltip {...props} classes={{ popper: className }} style={{alignSelf: 'flex-start'}} placement="right-start" />
// ))(({ theme }) => ({
//     [`& .${tooltipClasses.tooltip}`]: {
//         backgroundColor: theme.palette.common.white,
//         color: 'rgba(0, 0, 0, 0.87)',
//         boxShadow: theme.shadows[1],
//         fontSize: 11,
//         transform: 'translateY(-10px) !important'
//     },
// }));

const IndexPage = () => {
    const infectionsData = useStaticQuery(graphql`
        query {
            infectionsData {
                totalCases
                totalCasesYesterday
                lastModified
            }
        }
    `)

    const totalCases = infectionsData.infectionsData.totalCases;
    const totalCasesYesterday = infectionsData.infectionsData.totalCasesYesterday;

    const lastModified = infectionsData.infectionsData.lastModified;
    const dateModified = new Date(lastModified).toLocaleDateString("nl-NL");

    const infectionsDifference = parseFloat(totalCases) - parseFloat(totalCasesYesterday);
    const differenceString = (infectionsDifference > 0) ? '+' + infectionsDifference : infectionsDifference;

    return (
        <Box
            sx={{
                alignItems: 'center',
                display: 'flex',
                flexWrap: 'wrap',
                height: 'auto',
                justifyContent: 'center',
                textAlign: 'center',
                width: '100%'
            }}
            container
            justifyContent="center"
            alignItems="center"
        >
            <Box
                sx={{
                    alignItems: 'center',
                    display: 'flex',
                    justifyContent: 'center',
                    position: 'relative',
                    width: '100%'
                }}
            >
                <Typography
                    sx={{
                        fontSize: {
                            xs: '3rem',
                            sm: '5rem'
                        },
                        fontWeight: 'normal',
                        margin: '0'
                    }}
                    variant="h1"
                    component="h1"
                >
                    {totalCases}
                </Typography>
                <Typography
                    sx={{
                        bgcolor: (infectionsDifference > 0) ? '#a30808' : '#4BB543',
                        borderRadius: '15px',
                        fontSize: {
                            xs: '2rem',
                            sm: '3rem'
                        },
                        fontWeight: 'normal',
                        margin: '0 0 0 15px',
                        padding: '7.5px 10px'
                    }}
                    variant="h3"
                    component="h3"
                >
                    {'(' + differenceString + ')'}
                </Typography>
                {/*<InfectionsTooltip title="De landelijke coronabesmettingen ten opzichte van gisteren" placement="top-end">*/}
                    {/*<HelpOutline />*/}
                {/*</InfectionsTooltip>*/}
            </Box>
            <Typography sx={{fontSize: {xs: '0.75rem', sm: '1.25rem'}}} variant="h6" component="h6">({dateModified})</Typography>

        </Box>
    )
};

export default IndexPage
